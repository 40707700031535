import styled from "styled-components";

export const Image = styled.img`
  width: 90%;
  height: auto;
  border: 2px solid #e2284a;
  border-radius: 15px;
  box-shadow: 12px 12px 12px black;

  @media screen and (max-width: 1366px) {
    box-shadow: none;
  }
`;
