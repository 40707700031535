export const DiaryTableImage =
  "https://lh3.googleusercontent.com/pw/ACtC-3cD7tqBCmhpQpHMatjNWxNTUsrCWCpnQacCsgPlSjQmA3VMXb6KEC5iGVmRyJU0hGhbY-YuR2FgO80tO5uXtWO2xw2VidV8QM66QJRLKyFOAGZgFt-Kjdoqmz-P3viwmG8Ibkpal0PF9oiw-JSLJVw8=w396-h800-no";

export const AddLessonImage =
  "https://lh3.googleusercontent.com/pw/ACtC-3dQy8_LJznV6ftUKbZPgUX8-VGNyxkZXlPptm-Myu2FxRU99R5kdsZGkOmoHdYBBVS3kkpA09B5_YDRTVWavlIVaptJ2vbshO8YKSGSnA1UJdPwPeYH8UXHNm8XWji2wljZcwOb_TtzTHmxvZSTcl4Q=w396-h800-no";

export const LessonTableImage =
  "https://lh3.googleusercontent.com/pw/ACtC-3fMi5FiRWLnDz1R5JFvMAcR6A7VU2VFlRNiWbm64PSKhu6WAVmY7oAUhl1wZTDzPDeINarsK3GWHLS6iKzCEUiQ3MiQFW3zTx8DU_yEaAI1GpPK5oLeHpGRWUh_uD9IvY3JoIxmKiHG4u_C598YjEN8=w401-h800-no";

export const IncomeImage =
  "https://lh3.googleusercontent.com/pw/ACtC-3fChyGMBvyuaFpNZkEO6RRKVHH96K48n799MDE_ICQ6aB2TN5UyRLIg4EyX-gQKH55Fd5K5BRVnTY7KNlhV2Xj4RUzu9HZKrI3iG88RpJwOKOrnt4G8AV6GWq_WdLxrU5v0whEhBMqUAnBdAuf9QIQU=w404-h800-no";

export const AddPupilImage =
  "https://lh3.googleusercontent.com/pw/ACtC-3frCq0ZQBWn-kn74VWbSx8azwn7UqUOkDNeppbXhRIVgSlTlUuvLL4aAsqdNwtObib59wYub8B7bPvUEEG8Hz2z4S3ih6-LxpUrEQFWlRpS_0ICK89lkBJAamY9ng0jf4E3m3ZFUqhZDh2YnaR5mntz=w403-h800-no";

export const SliderImage =
  "https://lh3.googleusercontent.com/pw/ACtC-3f8Yqen3gRxObRpM_pmNg9qvuv9fbUZsq_7MiTY4VwwLck6-W6wth0aUzaRahsh6DZbg5_qLDD9woxXTE9yViKRKZQREkamZtYJCG_EQlmnOt72sGhzCaz9N5Bgv6p9EgPSjqy0ymsjV7XbXc5GVGG6=w402-h800-no";

export const SliderButtonsImage =
  "https://lh3.googleusercontent.com/pw/ACtC-3fLf79h3JkEOO56AZmzzevOumL-6qtax-dd4HpbefW0bSZYffwxF9kpfftORHhHK04iselII6ifTY_Txy3yUoc0siZXEempwaApjv6HS80giAqcDFdWAV5X6x58VLI1CJN5ratTUyt1mwMhkEltrXJS=w402-h800-no";

export const HomePageImage =
  "https://lh3.googleusercontent.com/pw/ACtC-3ffqPCrDH41c-Mw0Kcc3ArqfaW_p78kAUevSXlh14DKgUIZ6v2tgL8JLlHGvUNkCbXAK1DNMdoSKghc6mP1XjlZlloJ_SFwhfRYmb-8QxEiiubru3CGbRinMffUJRE-ZiWdOFYU0MWyZY-wKcclbM4=w800-h600-no";

export const BrowseMenuImage =
  "https://lh3.googleusercontent.com/pw/ACtC-3eSCti1muceXiTbNMzsvyQPhZpgMAMiPI-ERjUuwkZdiI1H2Tsh28wwsExGqgEQcRFEaGL9SHbk_nLqkQhUZE3b3_ZbuK62yfzJs6IxWE8AHaeI-tOmG49Dht65BAwnTbVBIh-k05AHM78wOdz0tDI=w800-h600-no";

export const LoginImage =
  "https://lh3.googleusercontent.com/pw/ACtC-3fHEkKAwbF_rRLTlz-mMeudx5zAu8CTmQRKwN8XQF7avoZAlCXkX9LUADBxBLslgSyOL6o71sDfsJKuMEpmgO0sodiudLYfrOPIsQoo39oIUGWrckkdNbc-JxHOerFMpDQrs6gmrXhMmEmccFx5vm4=w800-h600-no";

export const SelectBoxImage =
  "https://lh3.googleusercontent.com/pw/ACtC-3e6iGMH5I0O6OI3vOiVLfRBehyZew-D3bMcNNWkCawe48TaOYJJzc0z0i5OFPVna_etmV4rBdHrLDe-9rjARBv3AEnsYfBWVbIlnUQdj_r451Kj0GZj2J_B2ocjZgiqzBf_byiCE6n4jJKeeaGot3I=w800-h600-no";

export const CheckoutImage =
  "https://lh3.googleusercontent.com/pw/ACtC-3cKxfFHwtocxFl4cpGKXjquRwdQz_X73IHiOn-lr8sevh76tptxrgG1DK5FGUXa-W8Y3tdlEysMPw9roiyXoAahoJIcDtfPzeBevQ3lLJUBXbPJap7JcpyuAColmv1IiO_y_Ukf86PlElSdUP7Ajm8=w800-h600-no";

export const GithubFooterLogo =
  "https://lh3.googleusercontent.com/pw/ACtC-3c5lkvLWezOkCwvrhORgvRnANxNUG0WZDK8GA8jDgAw7a5a8Zf0uxqf1qOQylWPeRQ1kebu4v4pB1VGUGAIjtwlwMA1bHF_pX9rhlBhGILFcuSXl2bTZlmbAsoPeHnttJ6kp9CJ1waqXoyERxpzMtJJ=s200-no";

export const BreakfastUpcomingBookingsImage =
  "https://lh3.googleusercontent.com/pw/AP1GczN-SfQIPH1DQoJ6G68li9-9rYds7p7tzKQuu62LLVcBPahgzCR9TGsREqpmgy-jvRP6DfMufcvx94d4PiSM7Z5-Mv9ID39IF2ENNqQRZlwa3zmFWnVZw-TXIHb6QptUf_ULkpnyD6xgdBC2yf4xzFN2=w1600-h1600-s-no-gm?authuser=0";

export const BreakfastBookSessionImage =
  "https://lh3.googleusercontent.com/pw/AP1GczMT-0PkhedqnIgLj2Z1_1phZKSoO9y-0GppxmuSsxhltRqoVqdQOVGGgI8arH9rOmOZVPVjjO0KZ39ZQM497ppzPQebTFMm53bKNB4pwlMRi8czsycQodpnt6pEE11LgpOJaM9xx14xGMMMv21AyKDX=w1600-h1600-s-no-gm?authuser=0";

export const BreakfastConfirmationImage =
  "https://lh3.googleusercontent.com/pw/AP1GczOZ8ga51mFO7cahm-WbXWS9P9x5yqbxacZRRov6apnTQEF8iPWvhYkU-GRPt62aQIEkGUKYwCr4cQyjCrsv23pTPZpemY2geiJ_mq8YJFuNLGGIzmvJncD3kRJPU6VfTWbyLwdTxBzyBSwiQuVKUVfE=w1600-h1600-s-no-gm?authuser=0";

export const BreakfastAddFundsImage =
  "https://lh3.googleusercontent.com/pw/AP1GczMoIS7jg4iqRIk_42i_IUraUeoCCLU6HQgHhMqJgYfXJ73Lx6bHJzfgVZceRphOfZNv2J2uKXsxvL6jFrw-tEssUbbejJsH_LbKLavP3GVn7mCtN5qcQQ-E7bFK4cNpZmuhZ_IkNKpwsE1sSRhYTCpq=w1600-h1600-s-no-gm?authuser=0";

export const BreakfastCancelBookingImage =
  "https://lh3.googleusercontent.com/pw/AP1GczOnRSwG71lWLTVTpVZn2VcWGAnWfzEM1MneqCRfY9KdxLrarwErHZhbSCuHcwwoY5X3z84jKrYQyeC9DxmZpg4wOK2c9iG6aahx3tOO8M7f09KmhiWleNZLQ567CKZVQtZqF9X9TDcm1vWMBzb8CUgD=w1600-h1600-s-no-gm?authuser=0";

export const BreakfastDashboardImage =
  "https://lh3.googleusercontent.com/pw/AP1GczM9hMBfAi1zc_3u5W3rAGQRG0d6XmG7S1lgE4Q3zCNMo_q408QO0CfXQ86jdoPKiVqkDJ5ess1gO0mynz8n4q9f-N78xOBZmNAHiLzwrcvOncnJwEleoQhQIOfWbFyO1184PXMnTlJINJjFB481cYGL=w1600-h1600-s-no-gm?authuser=0";

export const BreakfastPdfImage =
  "https://lh3.googleusercontent.com/pw/AP1GczMZHLirMZ911tK4gdUrPBVTh_ooOfAFvGidAMeNqIAC2Gv_ObcnQkyBXtf18OqBY0rP3LIryGMSannJDwxSR43Hntu-pmtYCkr0naCHIxfei3uoymnl6gMjaFrfaZsNM-dd_hT-t4lbmCP_uazMp1hW=w1600-h1600-s-no-gm?authuser=0";

export const FisnNFryLogo =
  "https://lh3.googleusercontent.com/pw/AP1GczOTLI5hUESy-BU7AXnsE9e4ZORoJ8NbhYNc0MX9lP0t1O6rCUioafHZzs4drr0MeAhYpyVufEUR_LwcrADZweAx5_hVhF-ZWxSUuUS3BPngPbc7gU6LghPptlYYBN389YuElVoQd_cxDdTIIIeh_7Is=w1000-h1000-s-no-gm?authuser=0";

export const FishNFryMenuImage =
  "https://lh3.googleusercontent.com/pw/AP1GczMGVWRguLM91t6oYAPfOYAbkv5itBOemM4GgaR8QwIuSuiN6LQA79GWacv2JEipcGZywLRIUcPbuqJD6BGd2jfZHCRiOctwKbl5AcISH3UGIbsC1ukqByuao2kmI4dBz4vdL8lI-sD3sVN0PnkxEjQq=w750-h800-s-no-gm?authuser=0";

export const FishNFryItemsImage =
  "https://lh3.googleusercontent.com/pw/AP1GczNWKyeOlJSJCrSgRJNaye8mSzzOyP2aheI-05t65tIPmREeG0uYeevdmHTI-yrfeQlRN40DIBer9Ti7h5RMTMHiP0le4Ytu-rZgKj3_fEGnRcoFqyvegPBP8LWV5_nogMo7H2kHyZhc5isrubD5btPf=w750-h800-s-no-gm?authuser=0";

export const FishNFryOptionsImage =
  "https://lh3.googleusercontent.com/pw/AP1GczM0UWaE4CtEQiXXPTiEXByJfLdUy7rmeaXG3QXxkRTiHwuHrrqrQSCqsdLazRRhwpk_AdoYSRcaGGGskgkJlVpDqcHJLhX9ngGW0kB_rscemycfxbD_EQI4DGpw_5K7bNDItqjSaryaSQQlNiGIBnB_=w750-h800-s-no-gm?authuser=0";

export const FishNFryAddToCartImage =
  "https://lh3.googleusercontent.com/pw/AP1GczNc2J5guaw46SX2aYuilaSMjtc5VjHwVYCGli3G3O7jHWz3yxFDiuIi0xQDD56kmSaAqtLyt67YXihRKrgwgalzEZUnN-iDXQIwev1Ns6NVmE5Qyu3RWIe59uf3VKumS5G6EkW_lRU_GP4hZajQWI2N=w750-h800-s-no-gm?authuser=0";

export const FishNFryCheckoutImage =
  "https://lh3.googleusercontent.com/pw/AP1GczOBCpSMS9FFXZkDmhXJkSrDniOHXQ25gTt2tp1w4D1P601Xh_ovMsN-YR6TwXZLTABqLOEoXhesuuAP2fE20Nd_4Avh1yzppzXhERBvdPNisUcAQdGkT2VtOyogq3d7j1QXJXYGzPh9lggoGhacLmRC=w750-h800-s-no-gm?authuser=0";
