import styled from "styled-components";
import { Link } from "react-router-dom";

import { ProjectButtonDiv } from "../reuseable-styles/next-and-previous-project-div/next-and-previous-project-div.styles";
import { Image } from "../reuseable-styles/image/image.styles";

export const FooterDiv = styled.div`
  padding-bottom: 100px;

  @media screen and (max-width: 450px) {
    padding-bottom: 50px;
  }
`;

export const ButtonDiv = styled(ProjectButtonDiv)``;

export const SocialLogo = styled(Image)`
  width: 100px;
  height: 100px;
  border: none;
  margin: 0px auto 20px auto;
  transition: all 0.5s ease-in-out;
  box-shadow: none;

  &:hover {
    transform: scale(1.2);
  }

  @media screen and (max-width: 600px) {
    width: 50px;
    height: 50px;
  }
`;

export const ImagesLink = styled(Link)`
  text-decoration: none;
`;

export const PrivacyDiv = styled.div`
  margin: 10px 0px;
`;

export const CopyrightDiv = styled.div`
  padding-bottom: 20px;

  span {
    color: whitesmoke;
  }
`;
