import styled from "styled-components";

export const Button = styled.button`
  min-width: 165px;
  width: 400px;
  height: 100px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 25px;
  font-size: 20px;
  font-family: "Russo One";
  text-transform: capitalize;
  background-color: #e2284a;
  text-shadow: 1px 1px 1px black;
  color: whitesmoke;
  border-radius: 15px;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  outline: none;

  &.margin {
    margin: 20px 0px;
    padding: 5px;
  }

  &:hover {
    background-color: #ffe61b;
    color: black;
    text-shadow: none;
    transform: scale(1.05);

    @media only screen and (max-width: 1366px) {
      transform: none;
      transition: none;
      box-shadow: none;
    }
  }

  :active {
    transform: translate(0, 0.5rem);
    box-shadow: 0 0.1rem black;
  }

  @media only screen and (max-width: 800px) {
    width: 70%;
    padding: 5px;
    line-height: 10px;
  }

  @media (max-width: 450px) {
    width: 80%;
    height: 50px;
    font-size: 18px;
  }

  @media (max-width: 360px) {
    width: 90%;
  }
`;
