import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    html {
        height:100%;
        width:100%;
        background-color:blue;
            .particles {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -2;
          } 
    }
    
    body {
        margin: 0px auto 100px auto;
        font-family: 'Russo One', sans-serif, serif, -apple-system, BlinkMacSystemFont,  'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align:center;
        text-transform: capitalize;

        h1 {
            color: whitesmoke;
            text-decoration:underline;

            @media screen and (max-width:600px) {
                font-size:25px;
            }

            @media screen and (max-width:280px) {
                font-size:20px;
            }
        }

        h2 {
            color:#e2284a;

            &.data-protection {
                text-decoration:underline;
            }

            @media screen and (max-width:450px) {
                font-size:20px;
            }
        }
        
        p {
            font-size:20px;
            color:whitesmoke;

            @media screen and (max-width:450px) {
                font-size:16px;
            }

            @media screen and (max-width:320px) {
                font-size:14px;
            }
        }

        a {
            color:#e2284a;

            &:hover {
                color:#ffe61b;
            }
        }


        span {
            color:#e2284a;
        }

        ul {
            padding-left: unset;
            display: table;
            margin: 0 auto;
            font-size:18px;
            @media screen and (max-width:1366px) {
                list-style-type: none;
            }
        }
          
    
        li {
            margin-bottom: 10px;
            color:whitesmoke;
            font-size:20px;

            @media screen and (max-width: 800px) {
                font-size:18px;
            }

            @media screen and (max-width:320px) {
                font-size:14px;
            }
        }

        hr {
            border: 2px solid black;
            margin-bottom:40px;
            width:50%;

            @media screen and (max-width:1366px) {
                width:75%;
            }
        }
    }
`;
