import styled, { keyframes } from "styled-components";
import { slideInRight } from "react-animations";

const slideInRightAnimation = keyframes`${slideInRight}`;

export const ScrollButton = styled.button`
  width: 50px;
  height: 50px;
  outline: none;
  background-color: #e2284a;
  border-radius: 5px;
  z-index: 10;
  position: fixed;
  right: 20px;
  bottom: 20px;
  border: 2px solid black;
  animation: 2s ${slideInRightAnimation};

  @media screen and (max-width: 600px) {
    width: 40px;
    height: 40px;
    bottom: 40px;
    right: 7px;
  }

  @media screen and (max-width: 280px) {
    width: 35px;
    height: 35px;
  }
`;

export const UpArrowImage = styled.img`
  width: 100%;
  height: 100%;
`;
