import { Link } from "react-router-dom";

import Logo from "../../assets/logo-200x200.jpg";

import {
  HeaderDiv,
  LogoContainer,
  LogoImage,
  Option,
  Options,
} from "./header.styles";

const Header = () => (
  <HeaderDiv>
    <LogoContainer>
      <Link to="/">
        <LogoImage src={Logo} alt="logo" />
      </Link>
    </LogoContainer>

    <Options>
      <Option to="/projects">
        <p>Projects</p>
      </Option>

      <Option to="/about-me" className="link">
        <p>About</p>
      </Option>

      <Option to="/contact">
        <p>Contact</p>
      </Option>
    </Options>
  </HeaderDiv>
);

export default Header;
