import styled from "styled-components";

import Button from "../../custom-button/custom-button.component";

export const ProjectButtonDiv = styled.div`
  display: inline-block;
`;

// delete after final page updated
export const ProjectButton = styled(Button)`
  margin: 20px;
`;
