import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { ReactComponent as BackArrowSVG } from "../../assets/back-button-arrow/arrow-left-1.svg";
import { BackButton, RelativePositionDiv } from "./floating-back-button.styles";

function getWindowDimensions() {
  const { innerWidth: width } = window;
  return {
    width,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const FloatingBackButton = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      {width <= 1024 && location.pathname !== "/" && (
        <RelativePositionDiv>
          <BackButton onClick={() => navigate(-1)}>
            <BackArrowSVG />
          </BackButton>
        </RelativePositionDiv>
      )}
    </>
  );
};

export default FloatingBackButton;
