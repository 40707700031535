import styled from "styled-components";

export const Div = styled.div`
  width: 50%;
  margin: 0px auto 50px auto;
  padding: 20px 20px;
  background-color: black;
  border: 2px solid #e2284a;
  border-radius: 15px;
  box-shadow: 12px 12px 12px black;

  a {
    &.email {
      text-transform: lowercase;
      text-decoration: none;

      &:hover {
        color: #ffe61b;
      }
    }
  }

  @media screen and (max-width: 1366px) {
    width: 70%;
    box-shadow: none;
  }

  @media screen and (max-width: 450px) {
    padding: 10px 10px;
  }

  @media screen and (max-width: 375px) {
    width: 80%;
  }
`;
