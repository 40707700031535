import styled from "styled-components";
import { Link } from "react-router-dom";

export const HeaderDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  z-index: 3;
  height: 90px;
  border-bottom: 3px solid #e2284a;
  display: flex;
  justify-content: space-between;
`;

export const LogoContainer = styled.div`
  height: 100%;
  width: 100px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-top: 2px;

  @media screen and (max-width: 450px) {
    margin-left: 5px;
  }
`;

export const LogoImage = styled.img`
  height: 75px;
  width: 75px;
  border-radius: 5px;
  border: 2px solid #e2284a;

  @media screen and (max-width: 320px) {
    height: 60px;
    width: 60px;
  }
`;

export const Options = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
`;

export const Option = styled(Link)`
  padding: 0px 35px;
  transition: all 0.5s ease-in-out;
  text-shadow: none;
  text-decoration: none;

  p {
    color: #e2284a;

    &:hover {
      color: #ffe61b;
    }

    @media screen and (max-width: 375px) {
      font-size: 14px;
    }

    @media screen and (max-width: 320px) {
      font-size: 12px;
    }
  }

  &:hover {
    transform: scale(1.2);

    @media screen and (max-width: 1366px) {
      transform: none;
      transition: none;
    }
  }

  @media screen and (max-width: 800px) {
    padding: 0px 10px;
  }

  @media screen and (max-width: 280px) {
    padding: 0px 5px;
  }
`;
